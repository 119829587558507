:root {
  --cur-bg: radial-gradient(
    circle,
    #c3c4b4,
    #c0c2a7,
    #bec09a,
    #bbbd8c,
    #b8bb7f
  );

  --dark-bg: radial-gradient(
    circle,
    #282b2e,
    #212124,
    #19191a,
    #100f10,
    #000000
  );

  --light-bg: radial-gradient(
    circle,
    #c3c4b4,
    #c0c2a7,
    #bec09a,
    #bbbd8c,
    #b8bb7f
  );

  --light: #dadcc3;
  --dark: #282b2e;

  --secondary-font: #4f78a8;
}

// global styles
*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  -webkit-transition: all 100ms ease;
  -o-transition: all 100ms ease;
}

body {
  margin: 0;
  padding: 0;
  background: var(--light-bg);
  // background-size: cover;
  // background-position: top;
  font-family: 'Open Sans', sans-serif;

  font-weight: 400;
  color: var(--light);

  position: relative;
}

p {
  font-size: 1.2rem;
  letter-spacing: 2px;
  line-height: 1.25em;
}

.btn {
  outline: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: var(--light);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--dark);
}

h1,
header,
.project_header a {
  font-family: 'Playfair Display', serif;
}

// header styles

.header {
  background: transparent;
  height: 100vh;
  display: flex;
  flex-direction: column;

  & .nav {
    min-height: 6rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    & .header_left {
      & .logo {
        display: flex;
        align-items: center;
        font-size: 1.25em;
        gap: 10px;
        & span {
          color: var(--secondary-font);
        }
        & img {
          transform: scaleX(-1);
          width: 64px;
        }
      }
    }

    & .header_right {
      & .links {
        display: flex;
        gap: 2.5rem;
        font-size: 1.5em;
        max-width: 85rem;
        align-items: center;

        & .link {
          font-weight: 500;
          color: var(--dark);
        }

        & .bulb {
          background-color: var(--dark);
          color: var(--light);
          border-radius: 10em;
          width: 42px;
          height: 42px;

          display: flex;
          justify-content: center;
          align-items: center;

          & svg {
            width: 28px;
            height: 28px;
          }
        }

        & .fun-keys {
          color: var(--dark);

          // & .mode {
          //   cursor: pointer;
          //   & .bi-darkbulb {
          //     width: 28;
          //     height: 28;
          //   }
          //   padding: 5px 6px 0 6px;
          //   border-radius: 10rem;
          //   color: var(--light);

          //   background: var(--dark);
          // }

          // & .checkbox {
          //   display: none;
          // }
        }
      }
    }
  }

  // intro

  & .wrapper {
    width: 90%;
    margin: auto;
    text-align: center;
    text-transform: capitalize;
    // position: relative;

    & h1 {
      font-size: 6em;
      letter-spacing: 2px;

      & span {
        color: var(--secondary-font);
      }
    }
    & h2 {
      font-size: 2em;
      letter-spacing: 16px;
    }
  }
}

.main {
  // About Section
  & .about {
    height: 100vh;
    overflow: hidden;
    color: var(--dark);

    & .wrapper {
      display: grid;
      height: 100%;
      width: 90%;
      margin: 0 auto;
      padding: 0 3em;
      position: relative;
      grid-template-columns: 3fr 5fr;
      grid-template-rows: 1fr 7fr;
      column-gap: 5rem;

      & .about_header {
        font-size: 3rem;
        grid-column: 1/3;
        letter-spacing: 4px;
        text-align: center;
      }

      & .about_container {
        position: relative;
        display: flex;
        align-items: center;
        height: 600px;

        & .btn {
          background-color: transparent;
          color: var(--dark);
          position: absolute;
          top: 50%;
          padding: 5px;
          cursor: pointer;
          z-index: 1;

          & .bi {
            width: 32;
            height: 32;
          }
        }

        & .btn_left {
          left: -5%;
        }

        & .btn_right {
          right: -5%;
        }
        & .slides {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;

          & .about_sec {
            width: 100%;
            position: absolute;

            & .sec_header {
              letter-spacing: 6px;
              padding-left: 24px;
              font-size: 2rem;
            }

            & .sec_container {
              padding: 0.75rem 1rem;
              font-size: 2rem;

              & a {
                color: var(--dark);
                text-decoration: underline;
                font-style: italic;
              }
            }
          }
        }
      }

      & .img-container {
        height: 575px;
        overflow: hidden;
        position: relative;
        border-radius: 2.5em;

        // display: flex;
        // align-items: center;
        & .overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: url('/Images/Dark-background.jpg');
          background-position: bottom;
          background-size: cover;
          background-attachment: local, scroll;
          background-position: center center;
          z-index: -10;
          opacity: 0.3;
        }

        & .img_portrait {
          display: block;
          margin: 0 auto;
          width: 500px;
          margin-top: -75px;
        }
      }
    }
  }

  // Projects Section
  & .projects {
    height: 100vh;

    .wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 90%;
      margin: 0 auto;
      padding: 0 3em;
      position: relative;

      & .projects_header {
        font-size: 3rem;
        text-align: center;
        letter-spacing: 4px;
      }

      & .projects_container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        max-width: 70rem;
        min-width: 75%;

        & .projects_tab-container {
          display: flex;

          & .projects_tab {
            background-color: #dadcc3;
            margin: 0 0.5em;
            line-height: 2em;
            width: 150px;
            transform: translateY(12px);
            border-radius: 3px;
          }

          & .clicked {
            color: #dadcc3;
            background-color: #282b2e;
            transform: translateY(5px);
          }
        }

        & .projects_content-container {
          padding: 2em 1em;
          background-color: #282b2e;
          border-radius: 3px;
          min-height: 17.5em;
          z-index: 2;
          width: 100%;

          & .project_header {
            & a {
              color: #dadcc3;
            }
            color: #dadcc3;
            letter-spacing: 2px;
            line-height: 2em;
            font-size: 3rem;
            text-decoration: underline;
          }

          & .brief-description {
            color: #dadcc3;
            font-size: 1.2rem;
            line-height: 20px;
          }
        }
        & .hidden {
          display: none;
        }
      }
    }
  }
}

// .footer {
//   background: var(--dark);
//   height: 300px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;

//   & .contact-us {
//     width: 85%;
//     margin: 0 auto;

//     & .footer_header {
//       font-size: 3rem;
//       margin: 0.5em;
//     }

//   }
// }

.model-float {
  background: var(--dark);
  position: fixed;
  z-index: 100;
  box-shadow: 0px 0px 8px -2px var(--light);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 0.25rem 1.5rem;
  border-radius: 50rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 4rem;
  transition: all 100ms ease;

  & .links {
    display: flex;
    gap: 2rem;

    & .link {
      color: var(--light);
      & .bi {
        padding-top: 4px;
        width: 42;
        height: 42;
      }
    }
  }

  & .fun-keys {
    color: var(--light);
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    position: relative;
    min-height: 45px;

    & .bulb {
      background-color: var(--dark);
      color: var(--light);
      border-radius: 10em;
      width: 42px;
      height: 42px;

      display: flex;
      justify-content: center;
      align-items: center;

      & svg {
        width: 28px;
        height: 28px;
      }
    }

    & .mode {
      cursor: pointer;
      width: 32;
      height: 32;
    }

    & .checkbox {
      display: none;
    }

    & .btn {
      background: var(--dark);
      color: var(--light);
      & .bi {
        width: 32;
        height: 32;
      }
    }

    & .btn-arrow-up {
      & .bi-arrow-up-short {
        width: 32;
        height: 32;
      }
    }
  }
  & .btn-open {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--dark);
    color: var(--light);
    min-height: 45px;

    & .bi-plus {
      width: 32;
      height: 32;
    }
  }
  & .hidden {
    display: none;
  }
}

.hidden {
  display: none;
}

// media query for Tablets
@media only screen and (max-width: 1050px) {
  .header {
    & .nav {
      min-height: 5rem;
      & .header_left {
        & .logo {
          font-size: 1.2rem;
        }
      }
      & .header_right {
        & .links {
          gap: 1.5rem;

          & .link {
            font-size: 1.3rem;
          }
        }
      }
    }

    & .wrapper {
      h1 {
        font-size: 4em;
        letter-spacing: 2px;
      }

      h2 {
        font-size: 1.75em;
        letter-spacing: 12px;
      }
    }
  }

  .main {
    // header of every section
    & .sec_header {
      font-size: 3rem;
    }

    & .about {
      & .img-container {
        display: none;
      }
      & .about_container {
        grid-column: 1/4;
      }
    }
    & .projects {
      & .projects_content-container {
        & a {
          line-height: 0.5em;
          font-size: 2.5rem;
        }
      }

      & .projects_tab {
        font-size: 1.125rem;
        max-width: 6em;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .header {
    & .wrapper {
      padding: 0 1em;
      width: 100%;
    }
  }

  .main {
    & .section {
      & .wrapper {
        width: 100%;
      }
    }
  }

  .header {
    & .nav {
      min-height: 4rem;
      & .header_left {
        & .logo {
          font-size: 1.1rem;
        }
      }
      & .header_right {
        & .links {
          gap: 1rem;

          & .link {
            font-size: 1rem;
          }
        }
      }
    }
    & .wrapper {
      & .intro_header {
        font-size: 3.5rem;
      }
      & .intro_text {
        font-size: 1.5rem;

        letter-spacing: 8px;
      }
    }
  }

  .main {
    & .section .wrapper {
      padding: 0 2em;
    }
    & .about {
      & .sec_container {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    & .projects {
      & .projects_content-container {
        & a {
          line-height: 0.5em;
          font-size: 2rem;
        }
      }

      & .projects_tab {
        font-size: 1rem;
        max-width: 6em;
      }
    }
  }
}

@media only screen and (max-width: 525px) {
  .header {
    & .nav {
      display: block;

      & .header_right {
        display: none;
      }
      & .header_left {
        margin: 10px 0 0 10px;
      }
    }

    & .wrapper {
      & .intro_header {
        font-size: 2.5rem;
      }
      & .intro_text {
        font-size: 1.25rem;
        letter-spacing: 3px;
      }
    }
  }

  .main {
    & .section .wrapper {
      padding: 0 1em;
    }
    & .about {
      & .about_sec {
        & .sec_container {
          padding: 0;
          font-size: 1.25rem;
        }
        & .sec_header {
          padding: 0;
        }
      }
      & .hidden {
        display: none;
      }
    }
  }

  .model-float {
    display: flex;
    gap: 2rem;
    & .links {
      gap: 1rem;
    }
  }
}
